import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f38d93e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "md-navigation-drawer"
}
const _hoisted_2 = { class: "drawer-header" }
const _hoisted_3 = { class: "logo-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "drawer-content" }
const _hoisted_6 = {
  key: 1,
  class: "md-navigation-rail"
}
const _hoisted_7 = { class: "rail-header" }
const _hoisted_8 = { class: "rail-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_fab_extended = _resolveComponent("md-fab-extended")!
  const _component_md_navigation_item = _resolveComponent("md-navigation-item")!
  const _component_md_fab = _resolveComponent("md-fab")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "nav-toggle",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (!_ctx.rail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, { to: "/" }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: _ctx.ChatCaptainLogo,
                      alt: "ChatCaptain",
                      class: "logo"
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }),
                _createVNode(_component_md_button, {
                  class: "toggle",
                  variant: "icon",
                  icon: "menu_open",
                  grey: "",
                  onClick: _ctx.toggleDrawerRail
                }, null, 8, ["onClick"])
              ]),
              (_ctx.fab)
                ? (_openBlock(), _createBlock(_component_md_fab_extended, {
                    key: 0,
                    class: "fab-action",
                    color: "primary",
                    icon: _ctx.fab?.icon,
                    "not-extended": _ctx.rail,
                    onClick: _ctx.fab?.onClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.fab?.title), 1)
                    ]),
                    _: 1
                  }, 8, ["icon", "not-extended", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, key) => {
                return (_openBlock(), _createBlock(_component_md_navigation_item, {
                  key: key,
                  item: item
                }, null, 8, ["item"]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_md_button, {
                class: "toggle",
                variant: "icon",
                icon: "menu",
                grey: "",
                onClick: _ctx.toggleDrawerRail
              }, null, 8, ["onClick"]),
              (_ctx.fab)
                ? (_openBlock(), _createBlock(_component_md_fab, {
                    key: 0,
                    color: "primary",
                    icon: _ctx.fab?.icon,
                    onClick: _ctx.fab?.onClick
                  }, null, 8, ["icon", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, key) => {
                return (_openBlock(), _createBlock(_component_md_navigation_item, {
                  key: key,
                  item: item
                }, null, 8, ["item"]))
              }), 128))
            ])
          ]))
    ]),
    _: 1
  }))
}